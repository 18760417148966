import * as React from 'react';
import { graphql } from 'gatsby';
import WebsiteDefaultPage from '../components/Page/GridBase';
import { Advisors, TeamSection } from '../components/PageSpecific/leadership';
import { JoinSection } from '../components/SharedSections/index';
import { LIGHT_DARK } from '../constants';

const Leadership = ({ data }) => {

  return (
    <WebsiteDefaultPage pageState={{ theme: LIGHT_DARK.DARK }}>
      <TeamSection data={data} />
      <Advisors data={data} />
      <JoinSection />
    </WebsiteDefaultPage>
  );
};

export const query = graphql`
query LeadershipQuery {

  teamData: allSanityTeamData(sort: {fields: [displayOrder], order: ASC}) {
    edges {
      node {
        name
        title
        maxChLength {
          desktop
          mobile
        }
        mainImage {
          asset {
            gatsbyImageData(formats: [AUTO, WEBP, AVIF], placeholder: BLURRED)
          }
        }
        displayOrder
        displayOrder
      }
    }
  }
  advisorData: allSanityAdvisorData(sort: {fields: [displayOrder], order: ASC}) {
    edges {
      node {
        name
        title
        maxChLength{
          desktop
          mobile
        }
        mainImage {
          asset {
          gatsbyImageData(formats: [AUTO, WEBP, AVIF], placeholder: BLURRED)
          }
        }
        displayOrder
      }
    }
  }
}
`

export default Leadership;
