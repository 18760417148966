import React from 'react';
import styled from 'styled-components';
import { Button as BaseButton } from '../../Gui/Button';
import {
  alignSelf,
  bodyBold,
  bodyDefault,
  Color,
  columnSpacing,
  columnStartEnd,
  gridColumns,
  h2Desktop,
  h2Mobile,
  h3Desktop,
  marginBottom,
  marginLeft,
  marginTop,
  maxWidth,
  owlSelector,
  paddingBottom,
  typographyByBreakpoint,
  widths,
} from '../../../styles';

const Wrapper = styled.section`
  display: grid;
  ${gridColumns(8, 12)};
  ${columnSpacing(8, 16, 24)};
  ${maxWidth('unset', '68.8rem', '144rem')};
  margin-inline: auto;
`;

const Blurb = styled.div`
  ${marginTop(16, 0, 0)};
  justify-self: center;
  ${alignSelf('flex-start', 'flex-start', 'center')}
  ${widths('32.7rem', '32.7rem', '46.8rem')};
  ${columnStartEnd([1, 9], [7, 13], [7, 13])}

  .button-wrapper {
    text-decoration: none;
  }
`;
const Header = styled.h2`
  ${typographyByBreakpoint(h2Mobile, h2Mobile, h2Desktop)};
`;

const FounderDescHeader = styled.h2`
  ${typographyByBreakpoint(h2Mobile, h2Mobile, h3Desktop)};
  color: #ffffff;
`;



const DownloadHeader = styled.h2`
  ${typographyByBreakpoint(h2Mobile, h2Mobile, h2Desktop)};
  margin-top: 4rem;
  text-align: center;
`;

const EventDesc = styled.p`
  ${typographyByBreakpoint(bodyBold, bodyBold, h3Desktop)};
  ${marginTop(16, 16, 32)};
`;

const FounderDesc = styled.p`
  ${typographyByBreakpoint(bodyBold, bodyBold, bodyDefault)};
  ${marginTop(16, 16, 32)};
  color: #ffffff
`;

const SecondDesc = styled.p`
  margin-top: 0.8rem;
  ${marginBottom(24, 24, 32)};
  border-bottom: 4px solid ${Color.GREEN};
  ${paddingBottom(24, 24, 32)};
`;

const Button = styled(BaseButton)`
  margin-top: 2.4rem;
  margin-right: 2.4rem;
`;

const ImageContainer = styled.div`
  justify-self: center;
  ${marginLeft(-140, -158, -339)};
  ${columnStartEnd([1, 9], [1, 7], [1, 7])};

  .main-image {
    ${widths('45.4rem', '45rem', '85.3rem')};
  }
`;

const Point = styled.p`
  display: flex;
  column-gap: 2.1rem;
`;

const CheckPoints = styled.div`
  ${owlSelector('top', 16)};
`;

export { ImageContainer, Button, EventDesc, FounderDesc, FounderDescHeader, Header, DownloadHeader, Blurb, Wrapper, SecondDesc, CheckPoints, Point };
