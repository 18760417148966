import React, { useEffect } from 'react';
import styled, { createGlobalStyle } from 'styled-components';

import { Color, Width, Fonts, bodyDefault } from '../../styles';
import { usePageState } from '../Global/SiteContainer';
import { Location } from '@reach/router';
import Metadata from '../Gui/Metadata';

const Wrapper = styled.main`
  /* Override flex styles on Body component to allow for full-bleed sections */
  align-self: stretch;
`;

const GlobalStyle = createGlobalStyle`
  html{
    background: ${Color.OFF_WHITE};
  }

  body {
    background: ${({ bodyBackground }: { bodyBackground?: string }) => bodyBackground};
  }

  h1, h2, h3 {
    ${Fonts.QUESTRIAL};
  }

  h4, h5, h6, p {
    ${Fonts.WORK_SANS};
  }

  p{
    ${bodyDefault};
  }
`;

const GridBase = ({
  children,
  bodyBackground = Color.OFF_WHITE,
  maxWidth = Width.XL,
  pageState,
  seoMetadata,
}: {
  children: any;
  bodyBackground?: string;
  maxWidth?: number;
  pageState: { theme?: string };
  seoMetadata?: {
    title?: string;
    description?: string;
  };
}) => {
  const setPageTheme = usePageState(state => state.setPageTheme);
  useEffect(() => {
    setPageTheme(pageState?.theme);
  }, []);

  return (
    <>
      <GlobalStyle bodyBackground={bodyBackground} />
      <Location>{props => <Metadata seoMetadata={seoMetadata} {...props} />}</Location>

      <Wrapper>{children}</Wrapper>
    </>
  );
};

export default GridBase;
