import React from 'react';
import styled from 'styled-components';
import { padding } from '../../../styles';
import { Divider, H2, JumpToDiv, JumpToLink, PeopleGrid, PeopleInfoCard } from './SharedComponents';
import {ArrowDown } from "../../Svg";

const Wrapper = styled.section`
  display: grid;
  grid-template-columns: 1fr minmax(auto, 98.7rem) 1fr;

  & > * {
    grid-column: 2;
  }

  ${padding('64px 24px 80px', '96px 40px 104px', '120px 0px 160px')};
`;

const Advisors = ({ data }) => {
  const advisorData = data && data.advisorData && mapEdgesToNodes(data.advisorData)
  return (
    <Wrapper id="advisors">
      <JumpToDiv>
        <H2>Our Advisors</H2>
        <JumpToLink href="#team" isUp>
          Jump to Our Founder and CEO
          {ArrowDown}
        </JumpToLink>
      </JumpToDiv>
      <Divider />
      <PeopleGrid>
      {advisorData.map((node) =>
          <PeopleInfoCard
            name={node.name}
            title={node.title}
            mainImage={node.mainImage}
            maxChLength={node.maxChLength}
            key={node.id}
          />)}
      </PeopleGrid>
    </Wrapper>
  );
};

function mapEdgesToNodes (data) {
  if (!data.edges) return []
  return data.edges.map(edge => edge.node)
}


export default Advisors;
