import * as React from 'react';
import styled, { css } from 'styled-components';
import { GatsbyImage} from 'gatsby-plugin-image';

import {
  bodyDefault,
  Color,
  columnSpacing,
  gridColumns,
  h1Mobile,
  h1Desktop,
  h2Mobile,
  h2Desktop,
  h3Desktop,
  highContrast,
  linkDefault,
  rowSpacing,
  typographyByBreakpoint,
  Width, width, height, flex,
} from '../../../styles';

export const Divider = styled.div`
  background: ${Color.SEAFOAM_GREEN};
  height: 1px;

  margin-block-start: 16px;
  margin-block-end: 48px;

  @media screen and (min-width: ${Width.BREAK_XL}px) {
    margin-block-start: 32px;
    margin-block-end: 72px;
  }
`;

export const H2 = styled.h2<{
  $inputColor?: string;
}>`
  color: ${({ $inputColor }) => $inputColor || Color.BLACK};

  ${typographyByBreakpoint(h3Desktop, h1Mobile, h1Desktop)};
`;

export const JumpToDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const JumpToLink = styled.a<{
  $inputColor?: string;
  isUp?: boolean
}>`
  color: ${({ $inputColor }) => $inputColor || Color.GRAY};
  ${linkDefault};
  display: none;

  @media screen and (min-width: ${Width.BREAK_MD}px) {
    ${flex()};
  }

  svg {
    /* em so spacing adjust based on the link's font size */
    margin-inline-start: 0.5em;
    ${({ isUp }) => isUp && css`
      transform: rotate(180deg);
      path {
        stroke: ${Color.BLACK};
      }
    `}

    ${highContrast(
      css`
        color: CanvasText;
      `
    )};
  }
`;

export const PeopleGrid = styled.div`
  display: grid;
  ${gridColumns(1, 3, 3)}
  ${columnSpacing(0, 40, 80)}
  ${rowSpacing(40, 72, 72)}
`;

// Container for each person's card
const CardContainer = styled.div`
  display: grid;
  grid-template-columns: 6.4rem 1fr;
  grid-column-gap: 16px;

  @media print, screen and (min-width: ${Width.BREAK_MD}px) {
    grid-template-columns: 1fr;
    grid-template-rows: 10rem 1fr;
    grid-row-gap: 1.6rem;
    align-items: center;
  }
`;

// Circular picture for peoples' portraits
const PersonImage = styled.div`
  .gatsby-image-wrapper img {
    ${width('6.4rem', '10rem', '10rem')};
    ${height('6.4rem', '10rem', '10rem')};
    width: 6.4rem;
    height: 6.4rem;
  }
`;

const Name = styled.p<{
  $inputColor?: string;
  $mobileMaxChLength?: number;
  $desktopMaxChLength?: number;
}>`
  color: ${({ $inputColor }) => $inputColor || Color.BLACK};
  max-width: ${({ $mobileMaxChLength }) => $mobileMaxChLength || 15}ch;

  ${typographyByBreakpoint(h2Mobile, h2Mobile, h2Desktop)};

  @media screen and (min-width: ${Width.BREAK_MD}px) {
    max-width: ${({ $desktopMaxChLength }) => $desktopMaxChLength || 10}ch;
  }
`;

const Title = styled.p<{
  $inputColor?: string;
}>`
  color: ${({ $inputColor }) => $inputColor || Color.BLACK};
  ${bodyDefault};

  @media screen and (min-width: ${Width.BREAK_MD}px) {
    margin-block-start: 8px;
  }
`;

export type MaxChLength = {
  mobile?: number;
  desktop?: number;
};

type PeopleInfoCardProps = {
  name: string;
  title: string;
  inputColor?: string;
  maxChLength?: MaxChLength;
  mainImage: any;
};


export const PeopleInfoCard = ({ name, title, mainImage,inputColor, maxChLength = {} }: PeopleInfoCardProps) => {
  const { mobile, desktop } = maxChLength;
  
  return (
    <CardContainer>
      <PersonImage>
      <GatsbyImage image={mainImage.asset.gatsbyImageData} alt={`A headshot of ${name} smiling`} />
      </PersonImage>
      <div>
        <Name $inputColor={inputColor} $mobileMaxChLength={mobile} $desktopMaxChLength={desktop}>
          {name}
        </Name>
        <Title $inputColor={inputColor}>{title}</Title>
      </div>
    </CardContainer>
  );
};
